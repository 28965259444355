<template>
  <v-container fluid>
    <v-toolbar dense flat>
      <v-row no-gutters>
        <v-col cols="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="neueRechnungErstellen()"
                ><v-icon>add</v-icon></v-btn
              >
            </template>
            <span>Neue Rechnung</span>
          </v-tooltip>
        </v-col>
        <v-col cols="8" md="2" offset-md="6" class="text-right">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Suchen"
            single-line
            solo
            flat
            hide-details
            clearable
            clear-icon="close"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>

    <v-row class="mt-1">
      <v-col cols="12" md="10" offset-md="1">
        <v-card outlined>
          <v-card-title class="py-1">
            <v-tabs dense center-active centered v-model="tab">
              <v-tab v-for="(item, index) in tabs" :key="index">{{
                item
              }}</v-tab>
            </v-tabs>
          </v-card-title>
        </v-card>
        <v-card outlined class="mt-2">
          <v-data-table
            :headers="headers"
            :items="rechnungen"
            :search="search"
            height="calc(100vh - 280px)"
            dense
            fixed-header
            hide-default-footer
            :items-per-page="-1"
            :hide-default-header="$vuetify.breakpoint.xsOnly"
            no-data-text="Keine Rechnungen vorhanden"
          >
            <template v-slot:header="">
              <thead>
                <tr class="hidden-sm-and-up">
                  <th>Nr</th>
                  <th>Betrag</th>
                  <th>Status</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr
                v-on:dblclick="
                  (showRechnung = true) &&
                    (selectedRechnung = item) &&
                    (neueRechnung = false)
                "
              >
                <td style="width: 100px">{{ item.rechnungsnummer }}</td>
                <td class="hidden-xs-only" style="width: 130px">
                  {{ item.rechnungsdatum | date("DDMMYYYY") }}
                </td>
                <td class="hidden-xs-only">{{ item.kunde | user(kunden) }}</td>
                <td class="hidden-xs-only">{{ item.betreff }}</td>
                <td style="width: 140px" class="text-right">
                  {{ item.summe | money }}
                  <span v-if="item.mwst" class="grey--text"
                    ><br />{{ (item.summe * 1.19) | money }}</span
                  >
                </td>
                <td v-if="item.status === 'Entwurf'">
                  <v-chip small color="grey" label>{{ item.status }}</v-chip>
                </td>
                <td v-if="item.status === 'Offen'">
                  <v-chip small color="blue" label>{{ item.status }}</v-chip>
                </td>
                <td v-if="item.status === 'Gezahlt'">
                  <v-chip small color="green" label>{{ item.status }}</v-chip>
                </td>
                <td style="width: 20px" class="hidden-xs-only">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="downloadRechnung(item)"
                        ><v-icon>download</v-icon></v-btn
                      >
                    </template>
                    <span>Herunterladen</span>
                  </v-tooltip>
                </td>
                <td style="width: 20px" class="hidden-xs-only">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="duplicateRechnung(item)"
                        ><v-icon>content_copy</v-icon></v-btn
                      >
                    </template>
                    <span>Duplizieren</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showRechnung"
      width="80%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="neueRechnung">Neue Rechnung</span>
          <span v-else>Rechnung bearbeiten</span>
          <v-spacer></v-spacer>
          <v-btn icon class="white--text" @click="showRechnung = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-row v-if="selectedRechnung">
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedRechnung.betreff"
                label="Betreff"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-autocomplete
                dense
                outlined
                hide-details
                :items="kundenSelect"
                v-model="selectedRechnung.kunde"
                label="Kunde"
              ></v-autocomplete
            ></v-col>
            <v-col cols="12" md="3"
              ><v-autocomplete
                dense
                outlined
                hide-details
                :items="['Entwurf', 'Offen', 'Gezahlt']"
                v-model="selectedRechnung.status"
                label="Status"
              ></v-autocomplete
            ></v-col>
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedRechnung.rechnungsnummer"
                label="Rechnungsnummer"
                append-outer-icon="refresh"
                @click:append-outer="getRechnungsnummer()"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedRechnung.rechnungsdatum"
                label="Rechnungsdatum"
                append-outer-icon="refresh"
                @click:append-outer="getRechnungsdatum()"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedRechnung.zahlungBis"
                label="Zahlbar bis"
                append-outer-icon="refresh"
                @click:append-outer="getZahlbarBis()"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedRechnung.zahlungsDatum"
                label="Gezahlt am"
                append-outer-icon="refresh"
                @click:append-outer="getZahlungsdatum()"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3"
              ><v-checkbox
                dense
                outlined
                hide-details
                v-model="selectedRechnung.mwst"
                label="MwSt"
              ></v-checkbox
            ></v-col>
            <v-col cols="12" md="6"
              ><v-textarea
                dense
                outlined
                hide-details
                v-model="selectedRechnung.freitext"
                label="Freitext PDF"
              ></v-textarea
            ></v-col>
            <v-col cols="12" md="6"
              ><v-textarea
                dense
                outlined
                hide-details
                v-model="selectedRechnung.mailtext"
                label="Freitext Mail"
              ></v-textarea
            ></v-col>
            <v-col cols="12" class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn
                rounded
                small
                :disabled="!selectedRechnung.kunde"
                outlined
                class="mr-2"
                @click="addOffeneStunden()"
                >Offene Stunden hinzufügen</v-btn
              >
              <v-btn rounded small plain
                >Standard Stundenlohn:
                {{ getLohn(selectedRechnung.kunde) | money }}</v-btn
              >
            </v-col>
          </v-row>
          <v-simple-table class="mt-4">
            <tbody>
              <tr
                v-for="(item, index) in selectedRechnung.positionen"
                :key="index"
                class="pa-2"
              >
                <td style="width: 20px">{{ index + 1 }}.</td>
                <td class="py-2">
                  <span class="subtitle-2"
                    ><v-text-field
                      dense
                      outlined
                      hide-details
                      v-model="item.titel"
                      label="Titel"
                    ></v-text-field
                  ></span>
                  <br />
                  <v-textarea
                    class="caption"
                    row-height="2"
                    dense
                    outlined
                    hide-details
                    v-model="item.beschreibung"
                    label="Beschreibung"
                    rows="3"
                    auto-grow
                  ></v-textarea>
                </td>
                <td style="width: 140px">
                  <v-text-field
                    class="my-1"
                    dense
                    outlined
                    hide-details
                    label="Anzahl"
                    v-model="item.anzahl"
                    placeholder="5.00"
                  ></v-text-field>
                  <v-text-field
                    class="my-1"
                    dense
                    outlined
                    hide-details
                    label="Preis"
                    v-model="item.einzelpreis"
                    placeholder="10.00"
                  ></v-text-field>
                  <v-text-field
                    class="my-1"
                    dense
                    filled
                    hide-details
                    readonly
                    :placeholder="(item.anzahl * item.einzelpreis) | money"
                  ></v-text-field>
                </td>
                <td style="width: 20px">
                  <v-btn icon @click="removeRechnungPosition(index)"
                    ><v-icon>close</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td style="text-align: right">Summe:</td>
                <td style="width: 140px">
                  <v-text-field
                    class="my-2"
                    dense
                    filled
                    hide-details
                    readonly
                    :placeholder="summe | money"
                  ></v-text-field>
                </td>
                <td>
                  <v-btn text block @click="addRechnungPosition()"
                    ><v-icon>add</v-icon></v-btn
                  >
                </td>
              </tr>
              <tr v-if="selectedRechnung.mwst">
                <td></td>
                <td style="text-align: right">MwSt:</td>
                <td style="width: 140px">
                  <v-text-field
                    class="my-2"
                    dense
                    filled
                    hide-details
                    readonly
                    :placeholder="(summe * 0.19) | money"
                  ></v-text-field>
                </td>
                <td></td>
              </tr>
              <tr v-if="selectedRechnung.mwst">
                <td></td>
                <td style="text-align: right">Gesamt:</td>
                <td style="width: 140px">
                  <v-text-field
                    class="my-2"
                    dense
                    filled
                    hide-details
                    readonly
                    :placeholder="(summe * 1.19) | money"
                  ></v-text-field>
                </td>
                <td></td>
              </tr>
            </tfoot>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            class="red--text"
            icon
            @click="deleteRechnung(selectedRechnung.id)"
            ><v-icon>delete</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="primary"
            @click="downloadRechnung(selectedRechnung)"
            dark
            text
          >
            <v-icon icon>download</v-icon>
          </v-btn>
          <v-btn
            class="primary"
            @click="sendEmail()"
            :disabled="
              selectedRechnung.betreff === '' ||
              selectedRechnung.freitext === '' ||
              selectedRechnung.mailtext === '' ||
              selectedRechnung.kunde === ''
            "
            dark
            text
          >
            <v-icon class="mr-2" small>send</v-icon> per Mail senden
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="showRechnung = false">Abbrechen</v-btn>
          <v-btn
            class="primary"
            v-if="neueRechnung"
            @click="createRechnung()"
            dark
            text
          >
            Erstellen
          </v-btn>
          <span v-else>
            <v-btn class="primary mr-2" @click="updateRechnung()" dark text>
              Speichern
            </v-btn>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import JSPDF from "jspdf";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
export default {
  data() {
    return {
      search: "",
      showRechnung: false,
      neueRechnung: false,
      selectedRechnung: {
        positionen: [],
        betreff: "",
        kunde: "",
        status: "",
        rechnungsnummer: "",
        rechnungsdatum: "",
        zahlungBis: "",
        zahlungsDatum: "",
        freitext: "",
        mwst: true,
      },
      tab: 0,
      tabs: [],
      headers: [
        { text: "Nummer", value: "rechnungsnummer" },
        { text: "Datum", value: "rechnungsdatum" },
        { text: "Kunde", value: "kunde" },
        { text: "Betreff", value: "betreff" },
        { text: "Betrag", value: "betrag" },
        { text: "Status", value: "status" },
        { text: "", value: "" },
        { text: "", value: "" },
      ],
    };
  },
  computed: {
    rechnungen() {
      return this.getRechnungen();
    },
    kunden() {
      return this.$store.state.kunden.kunden;
    },
    kundenSelect() {
      let kunden = [];
      this.$store.state.kunden.kunden.forEach((kunde) => {
        kunden.push({
          value: kunde.id,
          text: kunde.firma + "(" + kunde.nachname + ", " + kunde.vorname + ")",
        });
      });
      return kunden;
    },
    summe() {
      let summe = 0;
      if (this.selectedRechnung.positionen !== undefined) {
        this.selectedRechnung.positionen.forEach((position) => {
          summe +=
            parseFloat(position.anzahl) * parseFloat(position.einzelpreis);
        });
      }
      return summe;
    },
  },
  methods: {
    async sendEmail() {
      const path = await this.getRechnungUrl(this.selectedRechnung);
      let kunde = this.$store.getters["kunden/kunde"](
        this.selectedRechnung.kunde
      );
      const email = {
        from: {
          email: "mail@benediktbreuer.de",
          name: "Benedikt Breuer",
        },
        to: [
          {
            email: kunde.mail,
            name: kunde.vorname + " " + kunde.nachname,
            kunde: this.selectedRechnung.kunde,
          },
        ],
        cc: [],
        bcc: [{ email: "mail@benediktbreuer.de", name: "Benedikt Breuer" }],
        subject: "Rechnung " + this.selectedRechnung.rechnungsnummer,
        text: this.selectedRechnung.mailtext,
        date: new Date(),
        attachments: [
          {
            filename: this.selectedRechnung.rechnungsnummer + ".pdf",
            path: path,
          },
        ],
      };
      console.log("email", email);
      this.$store.dispatch("emails/createEmail", email);
      console.log("sendEmail", email);
    },
    addOffeneStunden() {
      let zeiten = this.$store.state.zeiten.zeiten.filter(
        (item) =>
          item.abgerechnet === false &&
          item.kunde === this.selectedRechnung.kunde
      );
      let positions = zeiten.reduce((acc, curr) => {
        const projekt = curr.projekt || "Offene Stunden"; // Verwende 'Offene Stunden', wenn kein Projekt angegeben ist
        if (!acc[projekt]) {
          acc[projekt] = {
            titel:
              this.$store.state.projekte.projekte.find(
                (item) => item.id === projekt
              )?.titel || "Offene Stunden",
            beschreibung: "",
            anzahl: 0,
            einzelpreis: this.getLohn(this.selectedRechnung.kunde),
          };
        }
        const date = new Date(curr.datum);
        const dauer = Number(curr.dauer)
          .toFixed(2)
          .toString()
          .replace(".", ",");
        acc[projekt].anzahl += curr.dauer;
        acc[projekt].beschreibung +=
          date.toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
          }) +
          " | " +
          dauer +
          " h | " +
          curr.anmerkung +
          "\n";
        return acc;
      }, {});

      // Füge die erstellten Positionen zum ausgewählten Rechnungsobjekt hinzu
      for (let projekt in positions) {
        this.selectedRechnung.positionen.push(positions[projekt]);
      }
    },
    getLohn(item) {
      if (item) {
        return this.$store.state.kunden.kunden.find(
          (kunde) => kunde.id === item
        ).stundenlohn;
      } else {
        return 0;
      }
    },
    getRechnungen() {
      this.tabs = [];
      let rechnungen = [];
      this.$store.state.rechnungen.rechnungen.forEach((item) => {
        if (
          this.tabs.indexOf(parseInt(item.rechnungsnummer.split("-")[0])) === -1
        ) {
          rechnungen[item.rechnungsnummer.split("-")[0]] = {
            jahr: item.rechnungsnummer.split("-")[0],
            rechnungen: [],
          };
          this.tabs.push(parseInt(item.rechnungsnummer.split("-")[0]));
        }
        rechnungen[item.rechnungsnummer.split("-")[0]].rechnungen.push(item);
      });
      return rechnungen[this.tabs[this.tab]].rechnungen;
    },
    getRechnungsnummer() {
      const rechnungen = this.rechnungen;
      const rechnungsnummern = rechnungen.map((r) =>
        parseInt(r.rechnungsnummer.split("-")[1])
      );
      console.log(rechnungsnummern);
      const hoechsteRechnungsnummer = rechnungsnummern.reduce(
        (max, num) => Math.max(max, num),
        0
      );
      console.log(hoechsteRechnungsnummer);
      this.selectedRechnung.rechnungsnummer = `${new Date().getFullYear()}-${(
        hoechsteRechnungsnummer + 1
      )
        .toString()
        .padStart(3, "0")}`;
    },
    getRechnungsdatum() {
      this.selectedRechnung.rechnungsdatum = new Date()
        .toISOString()
        .substr(0, 10);
    },
    getZahlbarBis() {
      const rechnungsdatum = new Date();
      rechnungsdatum.setDate(rechnungsdatum.getDate() + 14);
      this.selectedRechnung.zahlungBis = rechnungsdatum
        .toISOString()
        .substr(0, 10);
    },
    getZahlungsdatum() {
      this.selectedRechnung.zahlungsDatum = new Date()
        .toISOString()
        .substr(0, 10);
    },
    createRechnung() {
      this.selectedRechnung.summe = this.summe;
      this.$store.dispatch("rechnungen/createRechnung", this.selectedRechnung);
      this.showRechnung = false;
      this.selectedRechnung = {};
    },
    duplicateRechnung(item) {
      this.selectedRechnung = item;
      this.neueRechnung = true;
      this.showRechnung = true;
    },
    updateRechnung() {
      this.selectedRechnung.summe = this.summe;
      this.$store.dispatch(
        "rechnungen/updateRechnungData",
        this.selectedRechnung
      );
      this.showRechnung = false;
      this.selectedRechnung = {};
    },
    deleteRechnung(id) {
      this.$store.dispatch("rechnungen/deleteRechnungData", id);
      this.showRechnung = false;
      this.selectedRechnung = {};
    },
    neueRechnungErstellen() {
      this.selectedRechnung = {
        positionen: [],
        betreff: "",
        kunde: "",
        status: "",
        rechnungsnummer: "",
        rechnungsdatum: "",
        zahlungBis: "",
        zahlungsDatum: "",
        freitext: "",
        mwst: true,
      };
      this.neueRechnung = true;
      this.showRechnung = true;
    },
    addRechnungPosition() {
      this.selectedRechnung.positionen.push({
        titel: "",
        beschreibung: "",
      });
    },
    removeRechnungPosition(index) {
      this.selectedRechnung.positionen.splice(index, 1);
    },
    downloadRechnung(item) {
      let rechnung = item;
      let kunde = this.$store.getters["kunden/kunde"](rechnung.kunde);
      const pdf = this.generateRechnung(item);
      const name = kunde.firma
        ? kunde.firma.replace(".", "").replace(" ", "-")
        : kunde.nachname + "-" + kunde.vorname;

      pdf.save("Rechnung-" + rechnung.rechnungsnummer + "-" + name + ".pdf");
    },
    async getRechnungUrl(item) {
      let rechnung = item;
      const pdf = this.generateRechnung(rechnung);
      console.log(pdf);
      const storage = getStorage();
      const year = rechnung.rechnungsnummer.split("-")[0];
      const storageRef = ref(
        storage,
        "rechnungen/" + year + "/" + rechnung.rechnungsnummer
      );
      const url = await uploadBytes(storageRef, pdf.output("blob")).then(
        (snapshot) => getDownloadURL(snapshot.ref)
      );
      return url;
    },
    generateRechnung(item) {
      let rechnung = item;
      let kunde = this.$store.getters["kunden/kunde"](rechnung.kunde);
      let doc = new JSPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
      });
      console.log(kunde);
      doc.setFont("Helvetica");
      // let img = new Image()
      // img.src = '/static/wappen.png'
      // doc.addImage(img, 115, 25, 90, 90)
      doc.setFontSize(11);
      // let lines1 = doc.splitTextToSize(block.veranstaltung.name, 44)
      // let lines2 = doc.splitTextToSize(block.veranstaltung.bemerkung, 44)
      // let lange = zeile + parseInt(parseInt(8) * lines1.length / 2) + parseInt(parseInt(8) * lines2.length / 2)
      // if (lange > 145) { (doc.addPage()) && (zeile = 11) }
      doc.setFont("Helvetica", "bold");
      doc.text("Benedikt Breuer", 21, 43);
      doc.setFont("Helvetica", "normal");
      doc.text(" | Rehfuesstraße 37 | 53115 Bonn", 52, 43);
      doc.text(kunde.firma, 21, 60);
      doc.text(kunde.anrede, 21, 65);
      doc.text(kunde.vorname + " " + kunde.nachname, 21, 70);
      doc.text(kunde.adresse, 21, 75);
      doc.text(kunde.plz + " " + kunde.ort, 21, 80);
      doc.setFont("Helvetica", "bold");
      doc.text("Rechnungsnr.", 21, 100);
      doc.text("Kundennr.", 61, 100);
      doc.text("Datum", 101, 100);
      doc.text("Zahlbar bis", 141, 100);
      doc.setFont("Helvetica", "normal");
      doc.text(rechnung.rechnungsnummer, 21, 105);
      doc.text(kunde.kundennummer, 61, 105);
      doc.text(
        new Date(rechnung.rechnungsdatum).toLocaleString(["de-de"], {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
        101,
        105
      );
      doc.text(
        new Date(rechnung.zahlungBis).toLocaleString(["de-de"], {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
        141,
        105
      );
      let zeile = 120;
      doc.setFont("Helvetica", "bold");
      doc.text(rechnung.betreff, 21, zeile);

      // Zusammenfassung Positionen
      doc.setFontSize(11);
      zeile += 5;
      doc.setTextColor(0, 0, 0);
      doc.setFont("Helvetica", "bold");
      zeile = parseInt(zeile) + 5;
      doc.text("Pos", 21, zeile);
      doc.text("Beschreibung", 31, zeile);
      doc.text("Menge", 140, zeile);
      doc.text("Preis", 170, zeile, { align: "right" });
      doc.text("Gesamt", 190, zeile, { align: "right" });
      doc.setFont("Helvetica", "normal");
      zeile = parseInt(zeile) + 2;
      doc.line(21, zeile, 189, zeile);
      zeile = parseInt(zeile) + 6;
      let pos = "1";
      let summe = 0;
      rechnung.positionen.forEach((position) => {
        if (
          parseFloat(position.anzahl) * parseFloat(position.einzelpreis) >
          0
        ) {
          doc.text(pos, 21, zeile);
          doc.text(position.titel, 31, zeile);
          doc.setTextColor(0, 0, 0);
          doc.text(parseFloat(position.anzahl).toFixed(2), 140, zeile);
          doc.text(
            parseFloat(position.einzelpreis).toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
            170,
            zeile,
            { align: "right" }
          );
          doc.text(
            (
              parseFloat(position.anzahl) * parseFloat(position.einzelpreis)
            ).toLocaleString("de-DE", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }) + " €",
            190,
            zeile,
            { align: "right" }
          );
          summe =
            summe +
            parseFloat(position.anzahl) * parseFloat(position.einzelpreis);
          zeile = parseInt(zeile) + 8;
          pos = String(parseInt(pos) + 1);
        }
      });
      doc.line(21, zeile - 4, 189, zeile - 4);
      doc.setFont("Helvetica", "bold");
      zeile = parseInt(zeile) + 1;
      if (rechnung.mwst) {
        doc.setFont("Helvetica", "normal");
        doc.text("Zwischensumme", 159, zeile, { align: "right" });
        doc.text(
          summe.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
          189,
          zeile,
          { align: "right" }
        );
        zeile += 5;
        doc.text("Mehrwehrtsteuer (19%)", 159, zeile, { align: "right" });
        doc.text(
          (summe * 0.19).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
          189,
          zeile,
          { align: "right" }
        );
        zeile += 3;
        doc.line(165, zeile, 189, zeile);
        zeile += 5;
        doc.setFont("Helvetica", "bold");
        doc.text("Summe", 159, zeile, { align: "right" });
        doc.text(
          (summe * 1.19).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
          189,
          zeile,
          { align: "right" }
        );
        zeile += 1;
      } else {
        doc.text("Summe", 159, zeile, { align: "right" });
        doc.text(
          summe.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
          189,
          zeile,
          { align: "right" }
        );
      }
      zeile = parseInt(zeile) + 2;
      doc.line(165, zeile, 189, zeile);
      zeile = parseInt(zeile) + 1;
      doc.line(165, zeile, 189, zeile);
      // Weiter
      doc.setFont("Helvetica", "normal");
      let lines1 = doc.splitTextToSize(rechnung.freitext, 160);
      doc.text(lines1, 21, (zeile += 10));
      zeile = zeile + parseInt((parseInt(11) * lines1.length) / 2);
      doc.setFont("Helvetica", "bold");
      doc.setFillColor(53, 64, 79);
      doc.rect(0, 265, 210, 297, "F");
      doc.setFontSize(9);
      doc.setTextColor(200, 200, 200);
      doc.setFont("Helvetica", "bold");
      doc.text("Webservice Breuer", 21, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("Rehfuesstraße 37", 21, 277);
      doc.text("53115 Bonn", 21, 282);
      doc.text("Steuernummer: 205/5037/3123", 21, 287);
      doc.text("Inhaber: Benedikt Breuer", 21, 292);
      doc.setFont("Helvetica", "bold");
      doc.text("Kontakt", 81, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("+49 163 444 5098", 81, 277);
      doc.text("mail@benediktbreuer.de", 81, 282);
      doc.text("benediktbreuer.de", 81, 287);
      doc.setFont("Helvetica", "bold");
      doc.text("Bankverbindung", 141, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("IBAN: DE07 1001 1001 2740 0831 39", 141, 277);
      doc.text("BIC: NTSB DEB1 XXX", 141, 282);

      // Neue Seite
      doc.addPage();
      doc.setFontSize(11);
      zeile = 25;
      doc.setTextColor(0, 0, 0);
      doc.setFont("Helvetica", "bold");
      zeile = parseInt(zeile) + 5;
      doc.text("Pos", 21, zeile);
      doc.text("Beschreibung", 31, zeile);
      doc.text("Menge", 140, zeile);
      doc.text("Preis", 170, zeile, { align: "right" });
      doc.text("Gesamt", 190, zeile, { align: "right" });
      doc.setFont("Helvetica", "normal");
      zeile = parseInt(zeile) + 2;
      doc.line(21, zeile, 189, zeile);
      zeile = parseInt(zeile) + 6;
      pos = "1";
      summe = 0;
      rechnung.positionen.forEach((position) => {
        let linesBeschreibung = doc.splitTextToSize(position.beschreibung, 105);
        if (
          parseInt(zeile) +
            parseInt((parseInt(11) * linesBeschreibung.length) / 2) +
            10 >
          260
        ) {
          doc.setFont("Helvetica", "bold");
          doc.text(
            "Zwischensumme " +
              summe.toLocaleString("de-DE", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) +
              " €",
            189,
            zeile + 5,
            { align: "right" }
          );
          doc.setFillColor(53, 64, 79);
          doc.rect(0, 265, 210, 297, "F");
          doc.setFontSize(9);
          doc.setTextColor(200, 200, 200);
          doc.text("Webservice Breuer", 21, 272);
          doc.setFont("Helvetica", "normal");
          doc.text("Rehfuesstraße 37", 21, 277);
          doc.text("53115 Bonn", 21, 282);
          doc.text("Steuernummer: 205/5037/3123", 21, 287);
          doc.text("Inhaber: Benedikt Breuer", 21, 292);
          doc.setFont("Helvetica", "bold");
          doc.text("Kontakt", 81, 272);
          doc.setFont("Helvetica", "normal");
          doc.text("+49 163 444 5098", 81, 277);
          doc.text("mail@benediktbreuer.de", 81, 282);
          doc.text("benediktbreuer.de", 81, 287);
          doc.setFont("Helvetica", "bold");
          doc.text("Bankverbindung", 141, 272);
          doc.setFont("Helvetica", "normal");
          doc.text("IBAN: DE07 1001 1001 2740 0831 39", 141, 277);
          doc.text("BIC: NTSB DEB1 XXX", 141, 282);
          doc.addPage();
          doc.setFontSize(11);
          zeile = 25;
          doc.setTextColor(0, 0, 0);
          doc.setFont("Helvetica", "bold");
          zeile = parseInt(zeile) + 5;
          doc.text("Pos", 21, zeile);
          doc.text("Beschreibung", 31, zeile);
          doc.text("Menge", 140, zeile);
          doc.text("Preis", 170, zeile, { align: "right" });
          doc.text("Gesamt", 190, zeile, { align: "right" });
          doc.setFont("Helvetica", "normal");
          zeile = parseInt(zeile) + 2;
          doc.line(21, zeile, 189, zeile);
          zeile = parseInt(zeile) + 6;
        }
        doc.text(pos, 21, zeile);
        doc.text(position.titel, 31, zeile);
        doc.setTextColor(140, 140, 140);
        doc.text(linesBeschreibung, 31, zeile + 7);
        doc.setTextColor(0, 0, 0);
        doc.text(parseFloat(position.anzahl).toFixed(2), 140, zeile);
        doc.text(
          parseFloat(position.einzelpreis).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
          170,
          zeile,
          { align: "right" }
        );
        doc.text(
          (
            parseFloat(position.anzahl) * parseFloat(position.einzelpreis)
          ).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
          190,
          zeile,
          { align: "right" }
        );
        summe =
          summe +
          parseFloat(position.anzahl) * parseFloat(position.einzelpreis);
        zeile = parseInt(zeile) + parseInt(5 * linesBeschreibung.length);
        zeile = parseInt(zeile) + 10;
        pos = String(parseInt(pos) + 1);
      });
      doc.line(21, zeile - 4, 189, zeile - 4);
      doc.setFont("Helvetica", "bold");
      zeile = parseInt(zeile) + 1;
      if (rechnung.mwst) {
        doc.setFont("Helvetica", "normal");
        doc.text("Zwischensumme", 159, zeile, { align: "right" });
        doc.text(
          summe.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
          189,
          zeile,
          { align: "right" }
        );
        zeile += 5;
        doc.text("Mehrwehrtsteuer (19%)", 159, zeile, { align: "right" });
        doc.text(
          (summe * 0.19).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
          189,
          zeile,
          { align: "right" }
        );
        zeile += 3;
        doc.line(165, zeile, 189, zeile);
        zeile += 5;
        doc.setFont("Helvetica", "bold");
        doc.text("Summe", 159, zeile, { align: "right" });
        doc.text(
          (summe * 1.19).toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
          189,
          zeile,
          { align: "right" }
        );
        zeile += 1;
      } else {
        doc.text("Summe", 159, zeile, { align: "right" });
        doc.text(
          summe.toLocaleString("de-DE", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) + " €",
          189,
          zeile,
          { align: "right" }
        );
      }
      doc.setFont("Helvetica", "normal");
      zeile = parseInt(zeile) + 2;
      doc.line(165, zeile, 189, zeile);
      zeile = parseInt(zeile) + 1;
      doc.line(165, zeile, 189, zeile);
      if (!rechnung.mwst) {
        doc.setFontSize(9);
        doc.text(
          "Gemäß § 19 UStG wird aufgrund der Kleinunternehmerregelung keine Umsatzsteuer erhoben.",
          21,
          258
        );
      }

      doc.setFillColor(53, 64, 79);
      doc.rect(0, 265, 210, 297, "F");
      doc.setFontSize(9);
      doc.setTextColor(200, 200, 200);
      doc.setFont("Helvetica", "bold");
      doc.text("Webservice Breuer", 21, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("Rehfuesstraße 37", 21, 277);
      doc.text("53115 Bonn", 21, 282);
      doc.text("Steuernummer: 205/5037/3123", 21, 287);
      doc.text("Inhaber: Benedikt Breuer", 21, 292);
      doc.setFont("Helvetica", "bold");
      doc.text("Kontakt", 81, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("+49 163 444 5098", 81, 277);
      doc.text("mail@benediktbreuer.de", 81, 282);
      doc.text("benediktbreuer.de", 81, 287);
      doc.setFont("Helvetica", "bold");
      doc.text("Bankverbindung", 141, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("IBAN: DE07 1001 1001 2740 0831 39", 141, 277);
      doc.text("BIC: NTSB DEB1 XXX", 141, 282);

      return doc;
    },
  },
};
</script>

<style>
.loader {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 10px;
  margin-top: 0;
  z-index: 1000;
}
</style>
