<template>
  <v-container fluid>
    <v-toolbar dense flat>
      <v-row no-gutters>
        <v-col cols="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="neueVertragErstellen()"
                ><v-icon>add</v-icon></v-btn
              >
            </template>
            <span>Neues Vertrag</span>
          </v-tooltip>
        </v-col>
        <v-col cols="8" md="2" offset-md="6" class="text-right">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Suchen"
            single-line
            solo
            flat
            hide-details
            clearable
            clear-icon="close"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-divider></v-divider>

    <v-row class="mt-1">
      <v-col cols="12" md="10" offset-md="1">
        <v-card outlined>
          <v-data-table
            :headers="headers"
            :items="vertrage"
            :search="search"
            dense
            height="calc(100vh - 310px)"
            fixed-header
            hide-default-footer
            :items-per-page="-1"
            :hide-default-header="$vuetify.breakpoint.xsOnly"
            no-data-text="Keine Verträge vorhanden"
          >
            <template v-slot:header="">
              <thead>
                <tr class="hidden-sm-and-up">
                  <th>Nr</th>
                  <th>Kunde</th>
                  <th>Betrag</th>
                  <th>Status</th>
                </tr>
              </thead>
            </template>
            <template v-slot:item="{ item }">
              <tr
                v-on:dblclick="
                  (showVertrag = true) &&
                    (selectedVertrag = item) &&
                    (neueVertrag = false)
                "
              >
                <td style="width: 100px">{{ item.vertragsnummer }}</td>
                <td>{{ item.betreff }}</td>
                <td>{{ item.kunde | user(kunden) }}</td>
                <td style="width: 140px" class="text-right">
                  {{ item.summe | money }}
                  <span v-if="item.mwst" class="grey--text"
                    ><br />{{ (item.summe * 1.19) | money }}</span
                  >
                </td>
                <td>
                  {{
                    item.abgerechnet.length > 0
                      ? new Date(
                          Math.max(
                            ...item.abgerechnet.map((date) =>
                              new Date(date).getTime()
                            )
                          )
                        ).toLocaleDateString("de-DE", {
                          month: "long",
                          year: "numeric",
                        })
                      : "-"
                  }}
                  <span v-if="item.zeitraum" class="grey--text"
                    ><br />{{ item.zeitraum }} Monat{{
                      item.zeitraum !== 1 ? "e" : ""
                    }}</span
                  >
                </td>
                <td v-if="item.status === 'Entwurf'">
                  <v-chip small color="grey" label>{{ item.status }}</v-chip>
                </td>
                <td v-if="item.status === 'Aktiv'">
                  <v-chip small color="green" label>{{ item.status }}</v-chip>
                </td>
                <td v-if="item.status === 'Beendet'">
                  <v-chip small color="red" label>{{ item.status }}</v-chip>
                </td>
                <td style="width: 20px" class="hidden-xs-only">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="printVertrag(item)"
                        ><v-icon>download</v-icon></v-btn
                      >
                    </template>
                    <span>Herunterladen</span>
                  </v-tooltip>
                </td>
                <td style="width: 20px" class="hidden-xs-only">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="duplicateVertrag(item)"
                        ><v-icon>content_copy</v-icon></v-btn
                      >
                    </template>
                    <span>Duplizieren</span>
                  </v-tooltip>
                </td>
                <td class="pa-0">
                  <v-sheet
                    :color="getAnzMonate(item).color"
                    height="100%"
                    class="d-flex justify-center align-center"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon @click="createRechnung(item)"
                          ><v-icon>published_with_changes</v-icon></v-btn
                        >
                      </template>
                      <span>
                        In Rechnung umwandeln
                        <br />Letzte Rechnung vor über
                        {{ getAnzMonate(item).anz }} Monat{{
                          getAnzMonate(item).anz !== 1 ? "en" : ""
                        }}
                        <br />Rechnung alle {{ item.zeitraum }} Monat{{
                          item.zeitraum !== 1 ? "e" : ""
                        }}
                      </span>
                    </v-tooltip>
                  </v-sheet>
                </td>
              </tr>
            </template>
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-card flat>
                <v-card-title class="justify-end pb-0">
                  Summe: {{ summeVertrage[0] | money }}
                </v-card-title>
                <v-card-title class="justify-end subtitle-1 pt-0">
                  Entwurf: {{ summeVertrage[1] | money }}
                </v-card-title>
              </v-card>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="showVertrag"
      width="80%"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span v-if="neueVertrag">Neue Vertrag</span>
          <span v-else>Vertrag bearbeiten</span>
          <v-spacer></v-spacer>
          <v-btn icon class="white--text" @click="showVertrag = false"
            ><v-icon>close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-4">
          <v-row v-if="selectedVertrag">
            <v-col cols="12" md="2"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedVertrag.vertragsnummer"
                label="Vertragsnummer"
                append-outer-icon="refresh"
                @click:append-outer="getVertragsnummer()"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="5"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedVertrag.betreff"
                label="Bezeichnung"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="5"
              ><v-autocomplete
                dense
                outlined
                hide-details
                :items="kundenSelect"
                v-model="selectedVertrag.kunde"
                label="Kunde"
              ></v-autocomplete
            ></v-col>
            <v-col cols="12" md="2"
              ><v-autocomplete
                dense
                outlined
                hide-details
                :items="['Entwurf', 'Aktiv', 'Beendet']"
                v-model="selectedVertrag.status"
                label="Status"
              ></v-autocomplete
            ></v-col>
            <v-col cols="12" md="2"
              ><v-text-field
                dense
                outlined
                hide-details
                v-model="selectedVertrag.vertragsdatum"
                label="Vertragsdatum"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="2"
              ><v-select
                dense
                outlined
                hide-details
                v-model="selectedVertrag.zeitraum"
                label="Abrechnungzeitraum"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
              ></v-select
            ></v-col>
            <v-col cols="12" md="2"
              ><v-select
                dense
                outlined
                hide-details
                v-model="selectedVertrag.laufzeit"
                label="Laufzeit"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
              ></v-select
            ></v-col>
            <v-col cols="12" md="2"
              ><v-checkbox
                dense
                outlined
                hide-details
                v-model="selectedVertrag.mwst"
                label="MwSt"
              ></v-checkbox
            ></v-col>
            <v-col cols="12" md="12"
              ><v-textarea
                dense
                outlined
                hide-details
                auto-grow
                v-model="selectedVertrag.freitext"
                label="Freitext"
              ></v-textarea
            ></v-col>
          </v-row>
          <v-row v-if="selectedVertrag">
            <v-col cols="12"> Abgerechnete Monate: </v-col>
            <v-col cols="12">
              <v-chip
                v-for="(item, index) in selectedVertrag.abgerechnet"
                :key="index"
                class="mr-2 mb-2"
              >
                {{
                  new Date(item).toLocaleDateString("de-DE", {
                    month: "long",
                    year: "numeric",
                  })
                }}
              </v-chip>
            </v-col>
          </v-row>
          <v-simple-table class="mt-4">
            <tbody>
              <tr
                v-for="(item, index) in selectedVertrag.positionen"
                :key="index"
                class="pa-2"
              >
                <td style="width: 20px">{{ index + 1 }}.</td>
                <td class="py-2">
                  <span class="subtitle-2"
                    ><v-text-field
                      dense
                      outlined
                      hide-details
                      v-model="item.titel"
                      label="Titel"
                    ></v-text-field
                  ></span>
                  <br />
                  <v-textarea
                    class="caption"
                    row-height="2"
                    dense
                    outlined
                    hide-details
                    v-model="item.beschreibung"
                    label="Beschreibung"
                    rows="3"
                    auto-grow
                  ></v-textarea>
                </td>
                <td style="width: 140px">
                  <v-text-field
                    class="my-1"
                    dense
                    outlined
                    hide-details
                    label="Anzahl"
                    v-model="item.anzahl"
                    placeholder="5.00"
                  ></v-text-field>
                  <v-text-field
                    class="my-1"
                    dense
                    outlined
                    hide-details
                    label="Preis"
                    v-model="item.einzelpreis"
                    placeholder="10.00"
                  ></v-text-field>
                  <v-text-field
                    class="my-1"
                    dense
                    filled
                    hide-details
                    readonly
                    :placeholder="(item.anzahl * item.einzelpreis) | money"
                  ></v-text-field>
                </td>
                <td style="width: 20px">
                  <v-btn icon @click="removeVertragPosition(index)"
                    ><v-icon>close</v-icon></v-btn
                  >
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td style="text-align: right">Summe:</td>
                <td style="width: 140px">
                  <v-text-field
                    class="my-2"
                    dense
                    filled
                    hide-details
                    readonly
                    :placeholder="summe | money"
                  ></v-text-field>
                </td>
                <td>
                  <v-btn text block @click="addVertragPosition()"
                    ><v-icon>add</v-icon></v-btn
                  >
                </td>
              </tr>
              <tr v-if="selectedVertrag.mwst">
                <td></td>
                <td style="text-align: right">MwSt:</td>
                <td style="width: 140px">
                  <v-text-field
                    class="my-2"
                    dense
                    filled
                    hide-details
                    readonly
                    :placeholder="(summe * 0.19) | money"
                  ></v-text-field>
                </td>
                <td></td>
              </tr>
              <tr v-if="selectedVertrag.mwst">
                <td></td>
                <td style="text-align: right">Gesamt:</td>
                <td style="width: 140px">
                  <v-text-field
                    class="my-2"
                    dense
                    filled
                    hide-details
                    readonly
                    :placeholder="(summe * 1.19) | money"
                  ></v-text-field>
                </td>
                <td></td>
              </tr>
            </tfoot>
          </v-simple-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            class="red--text"
            icon
            @click="deleteVertrag(selectedVertrag.id)"
            ><v-icon>delete</v-icon></v-btn
          >
          <v-spacer></v-spacer>
          <v-btn text @click="showVertrag = false">Abbrechen</v-btn>
          <v-btn
            class="primary"
            v-if="neueVertrag"
            @click="createVertrag()"
            dark
            text
            >Erstellen</v-btn
          >
          <v-btn class="primary" v-else @click="updateVertrag()" dark text
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import JSPDF from "jspdf";
export default {
  data() {
    return {
      search: "",
      showVertrag: false,
      neueVertrag: false,
      selectedVertrag: {},
      headers: [
        { text: "Nummer", value: "vertragsnummer" },
        { text: "Titel", value: "betreff" },
        { text: "Kunde", value: "kunde" },
        { text: "Betrag", value: "betrag" },
        { text: "Abgerechnet bis", value: "" },
        { text: "Status", value: "status" },
        { text: "", value: "" },
        { text: "", value: "" },
        { text: "", value: "zeitraum" },
      ],
    };
  },
  computed: {
    vertrage() {
      return this.$store.state.vertrage.vertrage;
    },
    kunden() {
      return this.$store.state.kunden.kunden;
    },
    kundenSelect() {
      let kunden = [];
      this.$store.state.kunden.kunden.forEach((kunde) => {
        kunden.push({
          value: kunde.id,
          text: kunde.firma + "(" + kunde.nachname + ", " + kunde.vorname + ")",
        });
      });
      return kunden;
    },
    summe() {
      let summe = 0;
      if (this.selectedVertrag.positionen !== undefined) {
        this.selectedVertrag.positionen.forEach((position) => {
          summe +=
            parseFloat(position.anzahl) * parseFloat(position.einzelpreis);
        });
      }
      return summe;
    },
    summeVertrage() {
      let summe = [0, 0];
      this.vertrage.forEach((vertrag) => {
        if (vertrag.status === "Aktiv") {
          summe[0] += vertrag.summe / vertrag.laufzeit;
        }
        if (vertrag.status === "Entwurf") {
          summe[1] += vertrag.summe / vertrag.laufzeit;
        }
      });
      return summe;
    },
  },
  methods: {
    getAnzMonate(item) {
      const startDate = new Date(item.vertragsdatum);
      const abgerechnet = item.abgerechnet;
      const currentDate = new Date().setDate(0);
      const months = [];

      while (startDate < currentDate) {
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const formattedDate = `${year}-${month}`;
        if (!abgerechnet.includes(formattedDate)) {
          months.push(formattedDate);
        }
        startDate.setMonth(startDate.getMonth() + 1);
      }
      if (item.status === "Beendet" || item.status === "Entwurf") {
        return {
          anz: months.length,
          color: "white",
        };
      }
      if (months.length >= item.zeitraum) {
        return {
          anz: months.length,
          color: "green",
        };
      } else if (months.length > 0) {
        return {
          anz: months.length,
          color: "green lighten-4",
        };
      } else {
        return {
          anz: months.length,
          color: "white",
        };
      }
    },
    getVertragsnummer() {
      const vertragsnummern = this.vertrage.map((r) =>
        parseInt(r.vertragsnummer)
      );
      const hoechsteNummer = vertragsnummern.reduce(
        (max, num) => Math.max(max, num),
        0
      );
      this.selectedVertrag.vertragsnummer = `${(hoechsteNummer + 1)
        .toString()
        .padStart(3, "0")}`;
      return `${(hoechsteNummer + 1).toString().padStart(3, "0")}`;
    },
    createVertrag() {
      this.selectedVertrag.summe = this.summe;
      this.$store.dispatch("vertrage/createVertrag", this.selectedVertrag);
      this.showVertrag = false;
      this.selectedVertrag = {};
    },
    duplicateVertrag(item) {
      this.selectedVertrag = item;
      this.neueVertrag = true;
      this.showVertrag = true;
    },
    updateVertrag() {
      this.selectedVertrag.summe = this.summe;
      this.$store.dispatch("vertrage/updateVertragData", this.selectedVertrag);
      this.showVertrag = false;
      this.selectedVertrag = {};
    },
    deleteVertrag(id) {
      this.$store.dispatch("vertrage/deleteVertragData", id);
      this.showVertrag = false;
      this.selectedVertrag = {};
    },
    neueVertragErstellen() {
      this.selectedVertrag = {
        abgerechnet: [],
        vertragsnummer: this.getVertragsnummer(),
      };
      this.selectedVertrag.positionen = [];
      this.neueVertrag = true;
      this.showVertrag = true;
    },
    addVertragPosition() {
      if (this.selectedVertrag.positionen === undefined) {
        this.selectedVertrag.positionen = [];
      }
      this.selectedVertrag.positionen.push({
        titel: "",
        beschreibung: "",
      });
    },
    removeVertragPosition(index) {
      this.selectedVertrag.positionen.splice(index, 1);
    },
    createRechnung(item) {
      const startDate = new Date(item.vertragsdatum);
      const abgerechnet = item.abgerechnet;
      const currentDate = new Date().setDate(0);
      const months = [];

      while (startDate < currentDate) {
        const year = startDate.getFullYear();
        const month = String(startDate.getMonth() + 1).padStart(2, "0");
        const formattedDate = `${year}-${month}`;
        if (!abgerechnet.includes(formattedDate)) {
          months.push(formattedDate);
        }
        startDate.setMonth(startDate.getMonth() + 1);
      }
      if (months.length > 0) {
        const kunde = this.$store.getters["kunden/kunde"](item.kunde);
        let freitext = "";
        freitext +=
          kunde.anrede === "Frau"
            ? "Sehr geehrte Frau "
            : "Sehr geehrter Herr ";
        freitext += kunde.titel
          ? kunde.titel + " " + kunde.nachname + ",\n\n"
          : kunde.nachname + ",\n\n";
        freitext +=
          "hiermit stelle ich Ihnen vertragsgemäß meine Arbeit in Rechnung. Diese umfasst die Monate ";
        months.forEach((mon, index) => {
          item.abgerechnet.push(mon);
          if (index === 0) {
            freitext += new Date(mon).toLocaleDateString("de-DE", {
              month: "long",
              year: "numeric",
            });
          } else {
            if (index < months.length - 1) {
              freitext += ", ";
              freitext += new Date(mon).toLocaleDateString("de-DE", {
                month: "long",
                year: "numeric",
              });
            } else {
              freitext += " und ";
              freitext += new Date(mon).toLocaleDateString("de-DE", {
                month: "long",
                year: "numeric",
              });
            }
          }
        });
        freitext +=
          ". Ich bitte Sie den Betrag auf das untenstehende Konto zu überweisen.\n\nVielen Dank für die Zusammenarbeit.\n\nMit freundlichen Grüßen\nBenedikt Breuer";
        let rechnung = {
          betreff: item.betreff,
          freitext,
          kunde: item.kunde,
          rechnungsdatum: new Date().toISOString().substr(0, 10),
          rechnungsnummer: `${new Date().getFullYear()}-${"000"}`,
          status: "Entwurf",
          zahlungBis: new Date(new Date().setDate(new Date().getDate() + 14))
            .toISOString()
            .substr(0, 10),
          positionen: JSON.parse(JSON.stringify(item.positionen)),
        };
        rechnung.positionen.forEach((pos) => {
          pos.anzahl = months.length;
        });
        console.log(item);
        this.$store.dispatch("vertrage/updateVertragData", item);
        this.$store.dispatch("rechnungen/createRechnung", rechnung);
      } else {
        console.log("Keine unberechneten Monate");
      }
    },
    createProjekt(item) {
      let projekt = item;
      projekt.budget = item.summe;
      projekt.status = "Offen";
      projekt.start = new Date().toISOString().substr(0, 10);
      projekt.stundenlohn = 1;
      projekt.titel = item.betreff;
      projekt.zeiten = [];
      this.$store.dispatch("projekte/createProjekt", projekt);
    },
    printVertrag(item) {
      let vertrag = item;
      let kunde = this.$store.getters["kunden/kunde"](vertrag.kunde);
      let doc = new JSPDF({
        orientation: "p",
        unit: "mm",
        format: "a4",
      });
      doc.setFont("Helvetica");
      // let img = new Image()
      // img.src = '/static/wappen.png'
      // doc.addImage(img, 115, 25, 90, 90)
      doc.setFontSize(11);
      // let lines1 = doc.splitTextToSize(block.veranstaltung.name, 44)
      // let lines2 = doc.splitTextToSize(block.veranstaltung.bemerkung, 44)
      // let lange = zeile + parseInt(parseInt(8) * lines1.length / 2) + parseInt(parseInt(8) * lines2.length / 2)
      // if (lange > 145) { (doc.addPage()) && (zeile = 11) }
      doc.setFont("Helvetica", "bold");
      doc.text("Benedikt Breuer", 21, 43);
      doc.setFont("Helvetica", "normal");
      doc.text(" | Rehfuesstraße 37 | 53115 Bonn", 52, 43);
      doc.text(kunde.firma, 21, 60);
      doc.text(kunde.anrede, 21, 65);
      doc.text(kunde.vorname + " " + kunde.nachname, 21, 70);
      doc.text(kunde.adresse, 21, 75);
      doc.text(kunde.plz + " " + kunde.ort, 21, 80);
      doc.setFont("Helvetica", "bold");
      doc.text("Vertragsnr.", 21, 100);
      doc.text("Kundennr.", 61, 100);
      doc.text("Datum", 101, 100);
      doc.setFont("Helvetica", "normal");
      doc.text(vertrag.vertragsnummer, 21, 105);
      doc.text(kunde.kundennummer, 61, 105);
      doc.text(
        new Date(vertrag.vertragsdatum).toLocaleString(["de-de"], {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
        101,
        105
      );
      doc.setFont("Helvetica", "bold");
      doc.text(vertrag.betreff, 21, 125);
      doc.setFont("Helvetica", "normal");
      let lines1 = doc.splitTextToSize(vertrag.freitext, 168);
      doc.text(lines1, 21, 135);
      let zeile = 135 + parseInt((parseInt(11) * lines1.length) / 2);
      doc.setFont("Helvetica", "bold");
      doc.setFillColor(53, 64, 79);
      doc.rect(0, 265, 210, 297, "F");
      doc.setFontSize(9);
      doc.setTextColor(200, 200, 200);
      doc.setFont("Helvetica", "bold");
      doc.text("Benedikt Breuer", 21, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("Rehfuesstraße 37", 21, 277);
      doc.text("53115 Bonn", 21, 282);
      doc.text("Steuernummer: 205/5037/3123", 21, 287);
      doc.text("Inhaber: Benedikt Breuer", 21, 292);
      doc.setFont("Helvetica", "bold");
      doc.text("Kontakt", 81, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("+49 163 444 5098", 81, 277);
      doc.text("breuerbe@gmail.com", 81, 282);
      doc.text("benediktbreuer.de", 81, 287);
      doc.setFont("Helvetica", "bold");
      doc.text("Bankverbindung", 141, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("IBAN: DE07 1001 1001 2740 0831 39", 141, 277);
      doc.text("BIC: NTSB DEB1 XXX", 141, 282);
      doc.addPage();
      doc.setFontSize(11);
      zeile = 25;
      doc.setTextColor(0, 0, 0);
      doc.setFont("Helvetica", "bold");
      zeile = parseInt(zeile) + 5;
      doc.text("Pos", 21, zeile);
      doc.text("Beschreibung", 31, zeile);
      doc.text("Menge", 140, zeile);
      doc.text("Preis", 170, zeile, { align: "right" });
      doc.text("Gesamt", 190, zeile, { align: "right" });
      doc.setFont("Helvetica", "normal");
      zeile = parseInt(zeile) + 2;
      doc.line(21, zeile, 189, zeile);
      zeile = parseInt(zeile) + 6;
      let pos = "1";
      let summe = 0;
      vertrag.positionen.forEach((position) => {
        let linesBeschreibung = doc.splitTextToSize(position.beschreibung, 105);
        if (
          parseInt(zeile) +
            parseInt((parseInt(11) * linesBeschreibung.length) / 2) +
            10 >
          260
        ) {
          doc.setFont("Helvetica", "bold");
          doc.text("Zwischensumme " + summe.toFixed(2) + " €", 189, zeile + 5, {
            align: "right",
          });
          doc.setFillColor(53, 64, 79);
          doc.rect(0, 265, 210, 297, "F");
          doc.setFontSize(9);
          doc.setTextColor(200, 200, 200);
          doc.text("Benedikt Breuer", 21, 272);
          doc.setFont("Helvetica", "normal");
          doc.text("Rehfuesstraße 37", 21, 277);
          doc.text("53115 Bonn", 21, 282);
          doc.text("Steuernummer: 205/5037/3123", 21, 287);
          doc.text("Inhaber: Benedikt Breuer", 21, 292);
          doc.setFont("Helvetica", "bold");
          doc.text("Kontakt", 81, 272);
          doc.setFont("Helvetica", "normal");
          doc.text("+49 163 444 5098", 81, 277);
          doc.text("breuerbe@gmail.com", 81, 282);
          doc.text("benediktbreuer.de", 81, 287);
          doc.setFont("Helvetica", "bold");
          doc.text("Bankverbindung", 141, 272);
          doc.setFont("Helvetica", "normal");
          doc.text("IBAN: DE07 1001 1001 2740 0831 39", 141, 277);
          doc.text("BIC: NTSB DEB1 XXX", 141, 282);
          doc.addPage();
          doc.setFontSize(11);
          zeile = 25;
          doc.setTextColor(0, 0, 0);
          doc.setFont("Helvetica", "bold");
          zeile = parseInt(zeile) + 5;
          doc.text("Pos", 21, zeile);
          doc.text("Beschreibung", 31, zeile);
          doc.text("Menge", 140, zeile);
          doc.text("Preis", 170, zeile, { align: "right" });
          doc.text("Gesamt", 190, zeile, { align: "right" });
          doc.setFont("Helvetica", "normal");
          zeile = parseInt(zeile) + 2;
          doc.line(21, zeile, 189, zeile);
          zeile = parseInt(zeile) + 6;
        }
        doc.text(pos, 21, zeile);
        doc.text(position.titel, 31, zeile);
        doc.setTextColor(140, 140, 140);
        doc.text(linesBeschreibung, 31, zeile + 7);
        doc.setTextColor(0, 0, 0);
        doc.text(parseFloat(position.anzahl).toFixed(2), 140, zeile);
        doc.text(
          parseFloat(position.einzelpreis).toFixed(2) + " €",
          170,
          zeile,
          { align: "right" }
        );
        doc.text(
          (
            parseFloat(position.anzahl) * parseFloat(position.einzelpreis)
          ).toFixed(2) + " €",
          190,
          zeile,
          { align: "right" }
        );
        summe =
          summe +
          parseFloat(position.anzahl) * parseFloat(position.einzelpreis);
        zeile = parseInt(zeile) + parseInt(5 * linesBeschreibung.length);
        zeile = parseInt(zeile) + 10;
        pos = String(parseInt(pos) + 1);
      });
      doc.line(21, zeile - 4, 189, zeile - 4);
      doc.setFont("Helvetica", "bold");
      zeile = parseInt(zeile) + 1;
      if (vertrag.mwst) {
        doc.setFont("Helvetica", "normal");
        doc.text("Zwischensumme", 159, zeile, { align: "right" });
        doc.text(summe.toFixed(2) + " €", 189, zeile, { align: "right" });
        zeile += 5;
        doc.text("Mehrwehrtsteuer (19%)", 159, zeile, { align: "right" });
        doc.text((summe * 0.19).toFixed(2) + " €", 189, zeile, {
          align: "right",
        });
        zeile += 3;
        doc.line(165, zeile, 189, zeile);
        zeile += 5;
        doc.setFont("Helvetica", "bold");
        doc.text("Summe", 159, zeile, { align: "right" });
        doc.text((summe * 1.19).toFixed(2) + " €", 189, zeile, {
          align: "right",
        });
        zeile += 1;
      } else {
        doc.text("Summe", 159, zeile, { align: "right" });
        doc.text(summe.toFixed(2) + " €", 189, zeile, { align: "right" });
      }
      doc.setFont("Helvetica", "normal");
      zeile = parseInt(zeile) + 2;
      doc.line(165, zeile, 189, zeile);
      zeile = parseInt(zeile) + 1;
      doc.line(165, zeile, 189, zeile);
      if (!vertrag.mwst) {
        doc.setFontSize(9);
        doc.text(
          "Gemäß § 19 UStG wird aufgrund der Kleinunternehmerregelung keine Umsatzsteuer erhoben.",
          21,
          258
        );
      }

      doc.setFillColor(53, 64, 79);
      doc.rect(0, 265, 210, 297, "F");
      doc.setFontSize(9);
      doc.setTextColor(200, 200, 200);
      doc.setFont("Helvetica", "bold");
      doc.text("Benedikt Breuer", 21, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("Rehfuesstraße 37", 21, 277);
      doc.text("53115 Bonn", 21, 282);
      doc.text("Steuernummer: 205/5037/3123", 21, 287);
      doc.text("Inhaber: Benedikt Breuer", 21, 292);
      doc.setFont("Helvetica", "bold");
      doc.text("Kontakt", 81, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("+49 163 444 5098", 81, 277);
      doc.text("breuerbe@gmail.com", 81, 282);
      doc.text("benediktbreuer.de", 81, 287);
      doc.setFont("Helvetica", "bold");
      doc.text("Bankverbindung", 141, 272);
      doc.setFont("Helvetica", "normal");
      doc.text("IBAN: DE07 1001 1001 2740 0831 39", 141, 277);
      doc.text("BIC: NTSB DEB1 XXX", 141, 282);

      doc.save(
        "Vertrag-" +
          vertrag.vertragsnummer +
          "-" +
          kunde.nachname +
          "-" +
          kunde.vorname +
          ".pdf"
      );
    },
  },
};
</script>

<style>
.loader {
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  height: 10px;
  margin-top: 0;
  z-index: 1000;
}
</style>
