import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

// initial state
const state = {
  emails: [],
  loading: false,
  error: null,
};

// getters
const getters = {
  email: (state) => (id) => {
    return state.emails.find((email) => email.id === id);
  },
  loading(state) {
    return state.loading;
  },
  error(state) {
    return state.error;
  },
};

// actions
const actions = {
  loadEmails({ commit }) {
    commit("setLoading", true);
    console.log("Emails laden...");
    firebase
      .firestore()
      .collection("emails")
      .onSnapshot(
        (snapshot) => {
          const emails = [];
          snapshot.forEach((doc) => {
            emails.push({
              ...doc.data(),
              id: doc.id,
            });
            commit("setLoadedEmails", emails);
            commit("setLoading", false);
          });
        },
        (error) => {
          console.log(error);
          commit("setLoading", false);
        }
      );
  },
  // eslint-disable-next-line
  createEmail({ commit, getters }, payload) {
    firebase
      .firestore()
      .collection("emails")
      .add(payload)
      .catch((error) => {
        console.log(error);
      });
    // Reach out to firebase and store it
  },
  deleteEmailData({ commit }, payload) {
    commit("setLoading", true);
    firebase
      .firestore()
      .collection("emails")
      .doc(payload)
      .delete()
      .then(() => {
        commit("setLoading", false);
        commit("deleteEmail", payload);
      })
      .catch((error) => {
        console.log(error);
        commit("setLoading", false);
      });
    commit("setLoading", false);
  },
  updateEmailData({ commit }, payload) {
    commit("setLoading", true);
    const updateObj = {};
    Object.keys(payload).forEach((key) => {
      if (payload[key] !== undefined) {
        updateObj[key] = payload[key];
      }
    });
    firebase
      .firestore()
      .collection("emails")
      .doc(payload.id)
      .update(updateObj)
      .then(() => {
        commit("setLoading", false);
        commit("updateEmail", payload);
      })
      .catch((error) => {
        console.log(error);
        commit("setLoading", false);
      });
  },
};

// mutations
const mutations = {
  setLoadedEmails(state, payload) {
    state.emails = payload;
  },
  createEmail(state, payload) {
    state.emails.push(payload);
  },
  updateEmail(state, payload) {
    const email = state.emails.find((email) => {
      return email.id === payload.id;
    });
    if (payload.creatorID) {
      email.creatorID = payload.creatorID;
    }
    if (payload.date) {
      email.date = payload.date;
    }
    if (payload.bezahlt) {
      email.bezahlt = payload.bezahlt;
    }
    if (payload.fahrer) {
      email.fahrer = payload.fahrer;
    }
    if (payload.km) {
      email.km = payload.km;
    }
    if (payload.title) {
      email.title = payload.title;
    }
  },
  deleteEmail(state, payload) {
    let array = state.emails;
    for (var i = 0; i < array.length; i++) {
      if (array[i].id === payload) {
        array.splice(i, 1);
      }
    }
    state.emails = array;
  },
  setLoading(state, payload) {
    state.loading = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  clearError(state) {
    state.error = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
